import { ArrowDownward, ArrowUpward, StarOutline } from '@mui/icons-material';
import {
  Avatar,
  Button,
  LinearProgress,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { api as axios } from '../../../api';
import EditToken from '../../../screens/editToken';
import {
  convertToInternationalCurrencySystem,
  getFormattedTime,
  logoWrapper
} from '../../../utils';
import TokenChart from '../../coinInfo/tokenChart';
import { headerConstants } from './token.table.constants';
// const RedSwapBtn = {
//   display: 'flex',
//   width: '119px',
//   height: '36px',
//   padding: '10px 19px',
//   justifyContent: 'center',
//   alignItems: 'center',
//   gap: '10px',
//   borderRadius: '30px',
//   border: '1px solid #F93C3C',
//   flexShrink: 0,
//   color: '#F93C3C',
//   textAlign: 'center',
//   fontFamily: 'Poppins',
//   fontSize: '16px',
//   fontStyle: 'normal',
//   fontWeight: 500,
//   lineHeight: '100%',
//   marginTop: '-20px'
// };
// const NormalSwapBtn = {
//   display: 'flex',
//   width: '119px',
//   height: '36px',
//   padding: '10px 19px',
//   justifyContent: 'center',
//   alignItems: 'center',
//   gap: '10px',
//   borderRadius: '30px',
//   border: '1px solid #76C8A8',
//   flexShrink: 0,
//   color: '#76C8A8',
//   textAlign: 'center',
//   fontFamily: 'Poppins',
//   fontSize: '16px',
//   fontStyle: 'normal',
//   fontWeight: 500,
//   lineHeight: '100%',
//   marginTop: '-20px'
// };

function TokenTable({
  loading,
  token,
  blinkUpList,
  blinkDownList,
  href,
  swappable,
  getFormattedTime,
  showPecu,
  priceData,
  pecuBlinkUp,
  pecuBlinkDown
}) {
  const { PecuPrice } = useSelector((state) => state);
  const [coinVolume, setCoinVolume] = useState(0);
  const [trend, setTrend] = useState({});
  const [priceDown, setPriceDown] = useState(false);
  const [trendHourly, setTrendHourly] = useState({});

  const [tvl, setTvl] = useState(84513557);
  const { isAdmin } = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);
  const get_change_index_coin_hourly = () => {
    axios.get(`/wallet/get_change_index_coin_hourly`).then((res) => {
      setTrendHourly(res.data);
    });
  };

  const calculate_daily_volume = () => {
    axios.get(`/wallet/calculate_daily_volume`).then((res) => {
      setCoinVolume(res.data.total_volume);
    });
  };

  const get_change_index_coin = () => {
    axios.get(`/wallet/get_change_index_coin`).then((res) => {
      setTrend(res.data);
    });
  };
  const get_tvl = () => {
    axios.get(`/wallet/tvl`).then((res) => {
      setTvl(res.data.tvl);
    });
  };

  useEffect(() => {
    get_change_index_coin_hourly();
    get_change_index_coin();
    calculate_daily_volume();
    get_tvl();

    setInterval(() => {
      get_change_index_coin_hourly();
    }, 1000);
  }, []);

  return (
    <TableContainer
      sx={{
        backgroundColor: '#1C1D20',
        mt: 2,
        borderRadius: '0.5rem',
        minHeight: '80vh'
      }}
      component={Paper}
    >
      {loading && <LinearProgress color="inherit" />}
      {!loading ? (
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: ' 1px solid #1e2128'
            }
          }}
        >
          <TableHead>
            <TableRow>
              {headerConstants.tableCellNamings.map((header, index) => {
                return (
                  <TableCell style={{ color: 'white' }} key={index}>
                    {header}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {showPecu && (
              <TableRow
                className={
                  pecuBlinkUp ? 'blinkup' : pecuBlinkDown ? 'blinkdown' : ''
                }
              >
                <TableCell
                  style={{ color: 'grey', display: 'flex' }}
                  align="left"
                >
                  <StarOutline fontSize="small" />
                </TableCell>
                <TableCell className="twhite" align="left">
                  <Link
                    to={`/Pecu`}
                    state={{
                      price: PecuPrice,
                      symbol: 'PECU'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Avatar
                        className="rounded"
                        src={`https://dev.hootdex.org/hootdex/images/pecuCoin.png`}
                        alt="token logo"
                        style={{
                          height: '35px',
                          width: '35px',
                          fontSize: '18px'
                        }}
                      />

                      <span
                        style={{
                          marginLeft: '1rem',
                          fontSize: '14px',
                          color: 'grey',
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        {' '}
                        <small
                          style={{
                            fontSize: '13px',
                            color: 'white'
                          }}
                        >
                          {'PECU'}
                        </small>
                        <small
                          style={{
                            fontSize: '14px',
                            marginRight: '4px',
                            fontWeight: 400
                          }}
                        >{`${'Pecu Novus'}`}</small>
                      </span>
                    </div>
                  </Link>
                </TableCell>
                <TableCell className={'twhite grey'} align="left">
                  {' '}
                  {PecuPrice >= 1
                    ? PecuPrice.toFixed(5).toLocaleString('en-US')
                    : PecuPrice.toFixed(5)}
                </TableCell>
                <TableCell
                  className={
                    trendHourly.trend == '1' ? `twhite green` : `twhite red`
                  }
                  align="left"
                >
                  {trendHourly.trend == '1' ? (
                    <ArrowUpward sx={{ fontSize: '13px' }} />
                  ) : trendHourly.trend == '-1' ? (
                    <ArrowDownward sx={{ fontSize: '13px' }} />
                  ) : (
                    <ArrowUpward sx={{ fontSize: '13px' }} />
                  )}
                  {parseFloat(trendHourly.value).toFixed(4)}%
                </TableCell>
                <TableCell
                  className={trend.trend == '1' ? `twhite green` : `twhite red`}
                  align="left"
                >
                  {trend.trend == '1' ? (
                    <ArrowUpward sx={{ fontSize: '13px' }} />
                  ) : trend.trend == '-1' ? (
                    <ArrowDownward sx={{ fontSize: '13px' }} />
                  ) : (
                    <ArrowUpward sx={{ fontSize: '13px' }} />
                  )}
                  {parseFloat(trend.value).toFixed(4)}%
                </TableCell>

                <TableCell className="twhite grey" align="left">
                  <span>
                    {' '}
                    {convertToInternationalCurrencySystem(
                      coinVolume * PecuPrice
                    )}
                  </span>
                </TableCell>
                <TableCell className="twhite grey" align="left">
                  {' '}
                  {convertToInternationalCurrencySystem(PecuPrice * tvl)}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: 'grey'
                  }}
                >
                  <p style={{ fontSize: '12px', fontWeight: 300 }}>
                    {new Date()
                      .toUTCString()
                      ?.split(' ')
                      ?.slice(0, 4)
                      .join(' ')}
                  </p>
                  <p style={{ fontSize: '12px', fontWeight: 300 }}>
                    {new Date()?.toUTCString()?.split(' ')[4]} GMT
                  </p>
                </TableCell>
                <TableCell>
                  {' '}
                  <TokenChart symbol={'PECU'} />
                </TableCell>
              </TableRow>
            )}

            {token?.map((each, index) => {
              const currentPrice =
                priceData && priceData[each.identitySymbol]
                  ? priceData[each.identitySymbol]
                  : each.currentPrice;
              return (
                <TableRow
                  key={each.id}
                  className={
                    blinkUpList.includes(each.identitySymbol)
                      ? 'blinkup'
                      : blinkDownList.includes(each.identitySymbol)
                      ? 'blinkdown'
                      : ''
                  }
                >
                  <TableCell
                    style={{ color: 'grey', display: 'flex' }}
                    align="left"
                  >
                    <StarOutline fontSize="small" />
                  </TableCell>

                  <TableCell style={{ color: 'grey' }} align="left">
                    <Link
                      to={`/${href}/${
                        each.identitySymbol || each.symbol || each.token_symbol
                      }`}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <Avatar
                          className="rounded"
                          src={logoWrapper(each?.logo || each.image, each)}
                          alt={each.symbol?.slice(1) || each.token_symbol}
                          style={{
                            height: '35px',
                            width: '35px',
                            fontSize: '18px'
                          }}
                        />
                        <span
                          style={{
                            marginLeft: '1rem',
                            fontSize: '14px',
                            color: 'grey',
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          {' '}
                          <small style={{ fontSize: '13px', color: 'white' }}>
                            {each.identitySymbol ||
                              each.symbol ||
                              each.token_symbol}
                          </small>
                          <small
                            style={{
                              fontSize: '14px',
                              marginRight: '4px',
                              fontWeight: 400
                            }}
                          >
                            {each.name || each.token_name}
                          </small>
                        </span>
                      </div>
                    </Link>
                  </TableCell>

                  <TableCell style={{ color: 'grey' }} align="left">
                    {' '}
                    {currentPrice >= 1
                      ? currentPrice?.toFixed(5).toLocaleString('en-US')
                      : currentPrice?.toFixed(5)}
                  </TableCell>
                  <TableCell
                    className={
                      currentPrice - each.price1hour >= 0
                        ? `twhite green`
                        : `twhite red`
                    }
                    align="left"
                  >
                    {currentPrice - each.price1hour > 0 ? (
                      <ArrowUpward sx={{ fontSize: '13px' }} />
                    ) : currentPrice - each.price1hour < 0 ? (
                      <ArrowDownward sx={{ fontSize: '13px' }} />
                    ) : (
                      <ArrowUpward sx={{ fontSize: '13px' }} />
                    )}
                    {(((currentPrice - each.price1hour) / currentPrice) * 100)
                      .toFixed(4)
                      .toLocaleString('en-US')}
                    %
                  </TableCell>

                  <TableCell
                    className={
                      currentPrice - each.price24hour >= 0
                        ? `twhite green`
                        : `twhite red`
                    }
                    align="left"
                  >
                    {currentPrice - each.price24hour > 0 ? (
                      <ArrowUpward sx={{ fontSize: '13px' }} />
                    ) : currentPrice - each.price24hour < 0 ? (
                      <ArrowDownward sx={{ fontSize: '13px' }} />
                    ) : (
                      <ArrowUpward sx={{ fontSize: '13px' }} />
                    )}
                    {(((currentPrice - each.price24hour) / currentPrice) * 100)
                      .toFixed(4)
                      .toLocaleString('en-US')}
                    %
                  </TableCell>

                  <TableCell style={{ color: 'grey' }} align="left">
                    {convertToInternationalCurrencySystem(each.volume)}
                  </TableCell>

                  <TableCell style={{ color: 'grey' }} align="left">
                    {' '}
                    {convertToInternationalCurrencySystem(each.tvl)}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      color: 'grey'
                    }}
                  >
                    {' '}
                    <p style={{ fontSize: '12px', fontWeight: 300 }}>
                      {new Date()
                        .toUTCString()
                        ?.split(' ')
                        ?.slice(0, 4)
                        .join(' ')}
                    </p>
                    <p style={{ fontSize: '12px', fontWeight: 300 }}>
                      {new Date()?.toUTCString()?.split(' ')[4]} GMT
                    </p>
                    {/* {href === 'SynthCryptos' ? (
                  <>
                    {' '}
                    <p style={{ fontSize: '12px', fontWeight: 300 }}>
                      {new Date().toDateString()}
                    </p>
                    <p style={{ fontSize: '12px', fontWeight: 300 }}>
                      {new Date()?.toUTCString()?.split(' ')[4]} GMT
                    </p>
                  </>
                ) : (
                  <>
                    {' '}
                    <p style={{ fontSize: '12px', fontWeight: 300 }}>
                      {getFormattedTime(each.timestamp).toDateString()}
                    </p>
                    <p style={{ fontSize: '12px', fontWeight: 300 }}>
                      {
                        getFormattedTime(each.timestamp)
                          ?.toUTCString()
                          ?.split(' ')[4]
                      }{' '}
                      GMT
                    </p>
                  </>
                )} */}
                  </TableCell>

                  <TableCell>
                    {swappable ? (
                      <a
                        href={`https://app.hootdex.net/${each.identitySymbol}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TokenChart symbol={each.symbol} />
                        <button
                          className={
                            currentPrice - each.price1hour < 0
                              ? 'RedSwapBtn'
                              : 'NormalSwapBtn'
                          }
                        >
                          Swap
                        </button>
                      </a>
                    ) : (
                      <TokenChart symbol={each.symbol || each.token_symbol} />
                    )}
                  </TableCell>
                  {isAdmin && (
                    <TableCell>
                      {/* {' '}
                  <Button
                  
                  >
                    Edit
                  </Button> */}
                      <button
                        onClick={() => {
                          setOpen(!open);
                          setSelectedToken(each);
                        }}
                        className={'NormalSwapBtn'}
                      >
                        Edit
                      </button>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Skeleton
          sx={{ bgcolor: '#21242b', mt: 1 }}
          variant="rectangular"
          margin={'1rem'}
          height={100}
        />
      )}
      {open && (
        <EditToken token={selectedToken} setOpen={setOpen} open={open} />
      )}
    </TableContainer>
  );
}

export default TokenTable;
